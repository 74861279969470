import * as XLSX from "xlsx";
import moment from "moment"
import fa from "element-ui/src/locale/lang/fa";

export const MixinIndex = {
	data() {
		return {
			api: "",
			title: "文章",
			column_option: {
				status: [{id: 1, title: "正常"}, {id: 2, title: "草稿"}],
			},

			column_visible: [],
			init_data: {},
			search_data: {},
			selection_list: [],
			order: "",

			table_loading: true,
			page: 1,
			limit: 10,
			table_data: [],
			page_info: {
				total: 0,
				page: 1,
				limit: 10,
			},

			upload_loading: false,
			export_loading: false,

			create_visible: false,
			import_visible: false,
			export_visible: false,
		}
	},
	computed: {
		import_field() {
			return []
		},
		export_field() {
			return []
		},
		column() {
			return [
				{label: "ID", prop: "id", width: 100, sortable: true},
				{label: "标题", prop: "title", search: true},
				{label: "图片", prop: "image", type: "image"},
				{label: "作者", prop: "author"},
				{label: "PV", prop: "pv"},
				{label: "权重", prop: "sort", type: "sort"},
				{label: "状态", prop: "status", type: "option", search: true, option: this.column_option.status},
				{label: "发布时间", prop: "create_time", type: 'datetime', sortable: true},
				{label: "下架时间", prop: "end_time", type: 'datetime', sortable: true},
				{label: "管理员", prop: "admin.nickname"},
				{label: "操作", prop: "action", fixed: 'right', type: 'action'},
			]
		}
	},
	mounted() {
		this.all()
	},
	methods: {
		all() {
			const params = {
				page: this.page,
				limit: this.limit,
				order: this.order,
			}
			const params2 = Object.assign({}, params, this.init_data, this.search_data);
			this.table_loading = true;
			this.api.all(params2).then((res) => {
				this.table_data = res.list;
				this.page_info = res.page_info;
			}).finally(() => {
				this.table_loading = false;
			})
		},
		deleted(row) {
			this.api.delete({
				id: row.id
			}).then((res) => {
				this.$message.success('删除成功')
				this.all();
			})
		},
		exportAll() {
			if (this.export_loading) {
				return false;
			}
			this.export_loading = true;
			const params = Object.assign({}, {
				limit: 9999,
				order: this.order,
			}, this.init_data, this.search_data)
			this.api.all(params).then((res) => {
				this.exportFile(res.list)
			}).finally(() => {
				this.export_loading = false;
			})
		},
		saveAll(list) {
			this.api.import({
				list: list
			}).then((res) => {
				this.$message.success('导入成功');
				this.all();
				this.$refs.importRef.close();
			})
		},
		changeSort({sort, item}) {
			this.api.sort({
				id: item.id,
				sort: sort
			}).then((res) => {
				this.$message.success('修改成功');
				this.all();
			})
		},
		search() {
			this.page = 1;
			this.all();
		},
		clean() {
			this.search_data = {};
			this.search();
		},
		sizeChange(limit) {
			this.page = 1;
			this.limit = limit;
			this.all();
		},
		currentChange(page) {
			this.page = page;
			this.all();
		},
		deleteChoose() {
			if (this.selection_list.length <= 0) {
				this.$message.error('没有选中任何数据');
				return false;
			}
			var ids = [];
			this.selection_list.map((item, index) => {
				ids.push(item.id);
			})
			this.deleted({id: ids.join(',')});
		},
		selection(selection_list) {
			this.selection_list = selection_list;
		},
		sortChange({column, prop, order}) {
			if (prop && order) {
				this.order = prop + "," + (order === 'ascending' ? 'asc' : 'desc')
			} else {
				this.order = "";
			}
			this.all();
		},
		changeColumn(column_visible) {
			this.column_visible = column_visible;
		},
		create() {
			this.create_visible = true;
			this.$nextTick(() => {
				this.$refs['createRef'].init();
			})
		},
		closeCreate() {
			this.create_visible = false;
		},
		editor(row) {
			this.create_visible = true;
			this.$nextTick(() => {
				this.$refs['createRef'].init(row);
			})
		},
		uploadFile(e) {
			if (this.upload_loading) {
				return false;
			}
			this.upload_loading = true;
			const files = e.target.files;
			if (!files) {
				return false;
			}
			const file = files[0];
			const reader = new FileReader();
			reader.onload = (event) => {
				const data = event.target.result;
				const wb = XLSX.read(data, {
					type: 'binary',
					cellDates: true,//设为true，将天数的时间戳转为时间格式
				});
				const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
					defval: '',
					raw: false,
					dateNF: 'yyyy-mm-dd'
				});
				if (!json || json.length <= 0) {
					this.$message.error('未识别到有效数据');
				}
				const list = [];
				json.map((item, index) => {
					const d = {};
					this.import_field.map((v, i) => {
						d[v.prop] = item[v.prop]
					})
					list.push(d)
				})
				this.showImport(list);
				this.upload_loading = false;
			};
			reader.readAsBinaryString(file);
			document.querySelector('#file').value = "";
		},
		showImport(list = []) {
			this.import_visible = true;
			this.$nextTick(() => {
				this.$refs['importRef'].init(list);
			})
		},
		templateFile() {
			const prop = [];
			const label = [];
			this.import_field.map((item, index) => {
				prop.push(item.prop)
				label.push(item.require ? (item.label + '(必填)') : item.label)
			})
			const data = [
				prop,
				label,
				["请严格按照模板来填写,请勿修改第一行表头，否则可能会导致系统识别错误~"],
				["只支持新增数据，不支持修改已有数据"]
			];
			const workbook = XLSX.utils.book_new();
			const sheet1 = XLSX.utils.aoa_to_sheet(data);
			XLSX.utils.book_append_sheet(workbook, sheet1, '导入模板');
			XLSX.writeFile(workbook, `${this.title}导入模板.xlsx`);
		},
		exportFile(list) {
			const date = moment().format('YYYY-MM-DD')
			const data = [[]];
			this.export_field.map((item, index) => {
				data[0].push(item.label)
			})
			list.map((item, index) => {
				const d = []
				this.export_field.map((v, i) => {
					let text = this.getter(item, v);
					switch (v.type) {
						case 'date':
							text = item[v.prop] ? moment.unix(item[v.prop]).format('YYYY-MM-DD') : '-';
							break;
						case 'datetime':
							text = item[v.prop] ? moment.unix(item[v.prop]).format('YYYY-MM-DD HH:mm:ss') : '-';
							break;
						case 'option':
							text = v.option ? v.option.find((item, index) => {
								return Number(item.id) === Number(text)
							}) : {title: text};
							text = text?.title;
							break;
						default:
							break;
					}
					d.push(text || '')
				})
				data.push(d);
			})
			const workbook = XLSX.utils.book_new();
			const sheet1 = XLSX.utils.aoa_to_sheet(data);
			XLSX.utils.book_append_sheet(workbook, sheet1, `${this.title}`);
			XLSX.writeFile(workbook, `${this.title}导出-${date}.xlsx`);
		},
		getter(v, item) {
			let prop = item?.prop?.split('.') || [];
			console.log(prop);
			for (let i = 0; i < prop.length; i++) {
				if (!v) return null;
				v = v[prop[i]];
			}
			return v || item.default;
		},
	}
}
