import _ from "lodash";

export const MixinCreate = {
	props:{
		title:"",
		column_option:{
			type:Object,
			default:()=>{
				return {}
			}
		}
	},
	data(){
		return {
			api:"",
			query:{},
			form:{},
			show:false,
			visible:false,
			submit_loading:false,
		}
	},
	methods:{
		decodeData(data){
			return data;
		},
		encodeData(data){
			return data;
		},
		getDetail(){
			this.api.get({
				id:this.query.id
			}).then((res)=>{
				this.form = this.decodeData(res.detail);
			})
		},
		submit(){
			this.$refs['formRef'].validate((valid) => {
				if (!valid) {
					return false;
				}
				const data = this.encodeData(_.cloneDeep(this.form));

				this.submit_loading = true;
				this.api.create(data).then((res)=>{
					this.close();
					this.$emit('success')
					this.$message.success({
						message:'保存成功',
						duration:1500,
					});
				}).finally(()=>{
					this.submit_loading = false;
				})
			});
		},
		init(query = {}){
			this.query = query;
			if(this.query.id){
				this.getDetail();
			}else{
				this.form = {};
			}
		},
		close(){
			this.$emit('close')
		},
	}
}
